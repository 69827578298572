<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 17.1286C9.41874 17.3145 9.76427 17.413 10.0001 17.413C11.639 17.413 18.5716 12.6582 18.5716 6.86393C18.514 2.97182 14.1419 -0.637782 10.0001 4.44336C5.85843 -0.637782 1.48631 2.97319 1.42871 6.86668C1.42871 8.42222 1.92859 9.90281 2.69162 11.2365"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 17.4127C9.57052 17.4127 8.7775 17.0862 7.84097 16.5"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.42871 15.481L3.45393 17.5706C4.40712 14.8323 5.20186 13.6316 6.9993 12"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
